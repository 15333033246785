import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider } from '@mui/material/styles';
import theme from '@sanedi.epc/theme';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

if (process.env.REACT_APP_DISABLE_CONSOLE?.toLocaleLowerCase() === 'true') {
  console.debug = 
    console.trace =
    console.warn =
    console.error =
    console.info =
    console.log = () => {}
}

const queryClient = new QueryClient();
const adminTheme = theme(true);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <BrowserRouter basename={process.env.REACT_APP_SUB_PATH}>
      <ThemeProvider theme={adminTheme}>
        <QueryClientProvider client={queryClient} contextSharing>
          <App />
        </QueryClientProvider>
      </ThemeProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
