import React from 'react';
import { Outlet, Route, Routes } from 'react-router-dom';
import { Upload, InsertInvitation, Dashboard, Contacts, Assignment, AssignmentTurnedIn, Domain, Forum, Article, Login, Logout } from '@mui/icons-material';
import Sidebar from '@sanedi.epc/components/layout/sidebar';
import AuthContext from '@sanedi.epc/components/common/auth-context';
import AuthValidation from '@sanedi.epc/components/common/auth-validation';
import ToolbarHeading from '@sanedi.epc/components/common/toolbar-heading';
import LoaderSuspense from '@sanedi.epc/components/common/loader-suspense';
import AuthorizePage from '@sanedi.epc/pages/auth/authorize';
import LogoutPage from '@sanedi.epc/pages/auth/logout';
import withQueryClientProvider from '@sanedi.epc/components/form/with-query-client-provider';

import { SnackbarProvider } from 'notistack';
import CustomAlert from '@sanedi.epc/components/common/alert';

const Error404 = React.lazy(() => import('@sanedi.epc/pages/error404'));
const FaqModal = React.lazy(() => import('@sanedi.epc/components/form/ContentManagement/faq-modal'))
const PostModal = React.lazy(() => import('@sanedi.epc/components/form/ContentManagement/post-modal'))
const ListBuildingsPage = React.lazy(() => import('@sanedi.epc/pages/building/list-buildings'));
const BuildingPage = React.lazy(() => import('@sanedi.epc/pages/building/building-page'));
const EnergyInformationPage = React.lazy(() => import('@sanedi.epc/pages/energy-information/energy-information-page'));
const EnergyInfoNotesModal = React.lazy(() => import('@sanedi.epc/pages/energy-information/energy-info-notes'));
const EnergyInfoDocsModal = React.lazy(() => import('@sanedi.epc/pages/energy-information/energy-info-docs'));
const ViewEpcCertificatePage = React.lazy(() => import('@sanedi.epc/pages/energy-information/view-epc-certificate'));
const DashboardPage = React.lazy(() => import('@sanedi.epc/pages/dashboard'));
const DuplicateBuildingsModal = React.lazy(() => import('@sanedi.epc/pages/building/duplicate-buildings-modal'));
const HistoricalEnergyInfoModal = React.lazy(() => import('@sanedi.epc/pages/energy-information/historical-energy-info-modal'));
const BulkUploadPage = React.lazy(() => import('./pages/bulk-upload'));
const BulkInvitePage = React.lazy(() => import('./pages/bulk-invite'));
const UserManagement = React.lazy(() => import('./pages/user-management'));
const FaqManagement = React.lazy(() => import('./pages/faq-management'));
const PostManagement = React.lazy(() => import('./pages/post-management'));
const ListSanasBodiesAdminPage = React.lazy(() => import('./pages/list-sanas-bodies-admin'));
const SanasBodyModal = React.lazy(() => import('./pages/sanas-body-modal'));
const ListRegisteredProfessionalApplicationsPage = React.lazy(() => import('./pages/list-registered-professional-applications'));
const RegisteredProfessionalApplicationView = React.lazy(() => import('./pages/registered-professional-application-view'));
const RegisteredProfessionalAttachments = React.lazy(() => import('./pages/registered-professional-attachments'));

const alertComponents = {
  success: CustomAlert,
  error: CustomAlert,
  info: CustomAlert,
  warning: CustomAlert
};

function App() {
  const [token, setToken] = React.useState(localStorage.getItem('token'));
  
  return (
    <AuthContext.Provider value={[token, setToken]}>
      <AuthValidation />
      <SnackbarProvider
        Components={alertComponents}
        maxSnack={5}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Routes>
          <Route element={
            <Sidebar
              heading={<ToolbarHeading>National Building Energy Performance Register</ToolbarHeading>}
              entries={[
                {
                  icon: <Dashboard />,
                  label: 'Dashboard',
                  to: '/'
                },
                { divider: true },
                {
                  icon: <Assignment />,
                  label: 'Register Building',
                  to: '/register-building'
                },
                {
                  icon: <AssignmentTurnedIn />,
                  label: 'List Buildings',
                  to: '/list-buildings'
                },
                {
                  icon: <Upload />,
                  label: 'Bulk Upload',
                  to: '/bulk-upload'
                },
                {
                  icon: <Forum />,
                  label: 'FAQ Management',
                  to: '/faq-management'
                },
                {
                  icon: <Article />,
                  label: 'Article Management',
                  to: '/post-management'
                },
                {
                  icon: <InsertInvitation />,
                  label: 'Invitations',
                  to: '/bulk-invite'
                },
                {
                  icon: <Contacts />,
                  label: 'Manage Users',
                  to: '/user-management'
                },
                {
                  icon: <Domain />,
                  label: 'Accredited Bodies',
                  to: '/list-sanas-bodies'
                },
                {
                  icon: <AssignmentTurnedIn />,
                  label: 'Professional Applications',
                  to: '/list-professional-applications'
                },
                {
                  icon: <Login />,
                  label: 'Login',
                  to: process.env.REACT_APP_LOGIN_URL!,
                  alignBottom: true,
                  hideIfPredicate: () => process.env.NODE_ENV === 'production'
                },
                {
                  icon: <Logout />,
                  label: 'Log Out',
                  to: '/logout',
                  alignBottom: true
                }
              ]}
            >
              <Outlet />
            </Sidebar>
          }>
            <Route path='/' element={<LoaderSuspense><DashboardPage /></LoaderSuspense>} />
            <Route path='dashboard' element={<LoaderSuspense><DashboardPage /></LoaderSuspense>} />
            <Route path='register-building' element={<LoaderSuspense><BuildingPage key='register' isAdminSite /></LoaderSuspense>} />
            <Route path='edit-building/:buildingId' element={<LoaderSuspense><BuildingPage key='edit' isAdminSite /></LoaderSuspense>} />
            <Route path='clone-building/:clonedBuildingId' element={<LoaderSuspense><BuildingPage key='clone' isAdminSite /></LoaderSuspense>} />
            <Route path='view-epc-certificate/:energyInformationId' element={<LoaderSuspense><ViewEpcCertificatePage /></LoaderSuspense>} />
            <Route path='submit-energy-info/:buildingId' element={<LoaderSuspense><EnergyInformationPage isAdminSite /></LoaderSuspense>} />
            <Route path='edit-energy-info/:energyInfoId' element={<LoaderSuspense><EnergyInformationPage isAdminSite /></LoaderSuspense>} />
            <Route path='amend-energy-info/:energyInfoId' element={<LoaderSuspense><EnergyInformationPage isAmendment isAdminSite /></LoaderSuspense>} />
            <Route path='list-buildings' element={<LoaderSuspense outlet><ListBuildingsPage isAdminSite /></LoaderSuspense>}>
              <Route path='energy-info-notes/:energyInfoId' element={<LoaderSuspense modal><EnergyInfoNotesModal /></LoaderSuspense>} />
              <Route path='energy-info-docs/:buildingId/:energyInfoId' element={<LoaderSuspense modal><EnergyInfoDocsModal /></LoaderSuspense>} />
              <Route path='energy-info-docs/:buildingId' element={<LoaderSuspense modal><EnergyInfoDocsModal /></LoaderSuspense>} />
              <Route path='view-duplicates/:buildingId' element={<LoaderSuspense modal><DuplicateBuildingsModal isAdminSite /></LoaderSuspense>} />
              <Route path='view-history/:buildingId' element={<LoaderSuspense modal><HistoricalEnergyInfoModal isAdminSite /></LoaderSuspense>} />
            </Route>
            <Route path='list-sanas-bodies' element={<LoaderSuspense outlet><ListSanasBodiesAdminPage /></LoaderSuspense>}>
              <Route path='edit-sanas-body/:sanasBodyId' element={<LoaderSuspense modal><SanasBodyModal /></LoaderSuspense>} />
              <Route path='add-sanas-body' element={<LoaderSuspense modal><SanasBodyModal /></LoaderSuspense>} />
            </Route>
            <Route path='list-professional-applications' element={<LoaderSuspense outlet><ListRegisteredProfessionalApplicationsPage /></LoaderSuspense>}>
              <Route path='attachments/:applicationId' element={<LoaderSuspense modal><RegisteredProfessionalAttachments /></LoaderSuspense>} />
            </Route>
            <Route path='view-application/:applicationId' element={<LoaderSuspense><RegisteredProfessionalApplicationView /></LoaderSuspense>} />
            
            <Route path='bulk-upload' element={<LoaderSuspense><BulkUploadPage /></LoaderSuspense>} />
            <Route path='bulk-invite' element={<LoaderSuspense><BulkInvitePage /></LoaderSuspense>} />
            <Route path='user-management' element={<LoaderSuspense><UserManagement /></LoaderSuspense>} />
            <Route path='faq-management' element={<LoaderSuspense outlet><FaqManagement /></LoaderSuspense>} >
              <Route path='faq-modal' element={<LoaderSuspense modal><FaqModal /></LoaderSuspense>} />
              <Route path='faq-modal/:faqId' element={<LoaderSuspense modal><FaqModal /></LoaderSuspense>} />
            </Route>
            <Route path='post-management' element={<LoaderSuspense outlet><PostManagement /></LoaderSuspense>} >
              <Route path='article-modal' element={<LoaderSuspense modal><PostModal /></LoaderSuspense>} />
              <Route path='article-modal/:postId' element={<LoaderSuspense modal><PostModal /></LoaderSuspense>} />
            </Route>
          </Route>
          <Route path='authorize/:authToken' element={<AuthorizePage isAdmin onAuth={setToken} />} />
          <Route path="/logout" element={<LogoutPage />} />
          <Route path='/404' element={<LoaderSuspense><Error404 /></LoaderSuspense>} />
          <Route path='*' element={<LoaderSuspense><Error404 /></LoaderSuspense>} />
        </Routes>
      </SnackbarProvider>
    </AuthContext.Provider>
  );
}

export default withQueryClientProvider(App);
